import { defineStore } from 'pinia'
import { State } from '@/pinia/report/types'
import { GetReportOrderListParams } from '@/api/report/type'
import { getReportOrderList } from '@/api/report/report.api'

export const useReportStore = defineStore('report', {
	state: (): State => ({
		reportOrder: {
			data: [],
			pagination: {
				pages: 0,
				totals: 0
			},
			sum: 0
		}
	}),
	actions: {
		// old mutations
		updateReportOrderList(reportOrder: any) {
			this.reportOrder = reportOrder
		},
		// old actions
		async actionGetReportOrderList(params: GetReportOrderListParams) {
			try {
				const { data: reportData } = await getReportOrderList(params)
				this.updateReportOrderList( reportData)
			} catch (error) {
				return Promise.reject(error)
			}
		},
	}
})