import { IPaymentChannel } from '@/type/order'
import { DashboardType } from '../type'

export interface GetReportOrderListParams {
    shopId: string
    offset: number
    limit: number
    search: string | null
    paymentChannel: IPaymentChannel | null
    status: string | null
    courier: string | null
    startDate: string | null
    endDate: string | null
    dateType: ORDER_DATE_TYPE
}

export type CodCalendarParams = {
    shopId: string
    limit: number
    offset: number
    codCalendarIds: string | string[]
    search?: string
}

export type ReconcileParams = {
    shopId: string
    limit: number
    offset: number
    startDate?: Date | null
    endDate?: Date | null
}

export type ReconcileOrderParams = {
    shopId: string
    limit: number
    offset: number
    search?: string
    reconcileId: string
}

export enum RECONCILE_STATUS {
    RECONCILED = 'RECONCILED',
    COMPLETED = 'COMPLETED',
}

export enum FeeParamType {
    GENERATE_BILL = 'GENERATE_BILL',
    PICKUP = 'PICKUP',
}

export type FeeParams = {
    shopId: string
    limit: number
    offset: number
    startDate: string
    endDate: string
    search: string | null
    type: FeeParamType
}

export type CODPendingParams = {
    shopId: string
    limit: number
    offset: number
}

export type CODCalendarParams = {
    shopId: string
    startDate: string
    endDate: string
}

export enum ORDER_DATE_TYPE {
    CREATE = 'CREATE',
    PICKUP = 'PICKUP',
    DESTINATION = 'DESTINATION',
}

export interface ITransactionParams {
    creditTransactionId: string
    offset: number
    limit: number
    search: string
}

export type SocialPageParams = {
    pageIds: string[]
    offset: number
    limit: number
    startDate: Date | string
    endDate: Date | string
}

export interface SocialReportPageResponse {
    data: ReportData[]
    pagination: Pagination
    costOrder: number
    totalOrder: number
    averageOrderValue: number
    userAnalyticsChart: UserAnalytic[]
    timeAnalyticsChart: TimeAnalyticsChart[]
}

export interface TimeAnalyticsChart {
    key: number | string
    count: number
}

export interface ReportData {
    analyticsDate: string
    inboxCount: number
    successInboxCount: number
    commentCount: number
    successPercent: number
    userAnalytics: UserAnalytic[]
    cost: number
    averageOrderValue: number
}

export interface UserAnalytic {
    name: string
    code: string
    successInboxCount: number
    cost: number
    averageOrderValue: number
}

export interface Pagination {
    totals: number
    pages: number
}

export interface IGetCustomerParams {
    limit: number
    offset: number
    shopId: string
    search?: string | null
    sortBy?: any
    sortOrder: 'descending' | 'ascending' | null
}

export interface IPage {
    value: string
    name: string
    image?: any
	type: string
}

export interface IPageParams {
    value: string
	type?: string
}

export interface IGetCostParams {
    limit: number
    offset: number
    shopId: string
    startDate: string | null
    endDate: string | null
    selecteds: IPageParams[]
    // search?: string | null
    // sortBy?: any
    // sortOrder: 'descending' | 'ascending' | null
}

export interface IUpdateCostPayload {
    shopId: string
    _id: string
    sales: number
    costProduct: number
    costShipment: number
    costAds: number
    costPackage: number
    costOther: number
    costMarketplaceDetail: IPaymentDetails
    totalWaitPaymentDetail: number
    dashboardAnalyticsDate: string
}

export interface DashboardAnalyticsResponse {
    data:            DashboardAnalyticData[];
    totalCostSelect: TotalCost;
    pagination:      Pagination;
    listSelect: OrderChannel;
}

export interface DashboardAnalyticData {
    costProduct:  number;
    costShipment: number;
    costAds:      number;
    costPackage:  number;
    totalCost:    number;
    sales:        number;
    profit:       number;
    _id:          string;
    shopId:       string;
    orders:       Order[];
    createdAt:    Date;
    updatedAt:    Date;
    dashboardAnalyticsDate:    Date;
    orderChannel:  OrderChannel[];
    orderChannelSearch:  OrderChannel[];
    costMarketplaceDetail: IPaymentDetails
    totalWaitPaymentDetail: number

}

export interface Order {
    _id:                string;
    orderId:            string;
    costProductInOrder: number;
    saleInOrder:        number;
    createdAt:          Date;
    products:           Product[];
}

export interface Product {
    _id:       string;
    productId: string;
    name:      string;
    price:     number;
    sold:      number;
}

export interface Pagination {
    totals: number;
    pages:  number;
}

export interface IPaymentDetails {
    totalRevenue: number; // ยอดขายสินค้า
    totalFee: number; // ค่าธรรมเนียมรวม
    totalSettlement: number; // ยอดที่จะได้รับ
    CODFee: number; // cod charge
    commissionFee: number; // commission
    shippingFee: number; // shipment
    transactionFee: number; // credit payment etc.
    serviceFee: number; // marketplace fee
    promotionFee: number; // promotion ค่าธณรมเนียมการส่งเสริมการขาย
    refund: number; // เงินคืนผลเป็นบวก ไม่ติดลบ
    otherFee: number;
  }

export interface TotalCost {
    totalCostProduct:   number;
    totalCostShipment: number;
    totalCostAds:       number;
    totalCostPackage:   number;
    totalCostOther:     number;
    totalSales:         number;
    totalProfit:        number;
    orderCount:         number;
    bestSell:           BestSell[];
    bestProvinces:      BestProvinces[];
    orderChannel:        OrderChannel[];
    costMarketplaceDetail: IPaymentDetails
    totalWaitPaymentDetail: number
}

export interface BestSell {
    productId: string;
    name:      string;
    sold:      number;
}

export interface BestProvinces {
    provinceCode: number;
    provinceName: string;
    total:        number;
}

export interface OrderChannel {
    pageId:             string;
    pageInfo:           PageInfo;
    orderCount:         number;
    totalCostProduct:   number;
    totalSales:         number;
    channel:            string;
    bestSell:           BestSell[];
    bestProvinces:      BestProvinces[];
}

export interface PageInfo {
    imageUrl: number;
    name: string;
    channel?: string;
}

export enum COD_CALENDAR_STATUS {
    PENDING = 'PENDING',
    RECONCILED = 'RECONCILED',
    COMPLETED = 'COMPLETED',
  }

export interface CodCalendar {
    currentDate: Date | string | undefined;
    sumCost: number;
    sumTotal: number;
    costFreeze?: number;
    totalFreeze?: number;
    destinationDate: Date | string | undefined;
    transferTime?: Date | string | undefined;
    reconcileTime?: Date | string | undefined;
    status: COD_CALENDAR_STATUS | string;
    courierCod?: [
      {
        _id: string;
        cost: number;
        total: number;
        courier: string;
        status: COD_CALENDAR_STATUS | string;
        destinationDate?: Date | string | undefined;
        transferTime?: Date | string | undefined;
        reconcileTime?: Date | string | undefined;
      },
    ];
  }
export interface IFeeOpposeParams {
    shopId: string
    offset: number
    limit: number
    orderIds: string[]
}
